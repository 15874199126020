@use '@dashboard-experience/mastodon/lib/theme.scss';

.v1 {
  .settings-label {
    color: theme.$ui-navy-600;
    font-weight: bold;
  }
  .settings-description {
    padding-bottom: 0.2em;
    padding-top: 0.1em;
    margin-left: 4em;
    margin-bottom: 0.5rem;
  }
}

.v2 {
  .settings-label {
    margin-left: 0.5rem;
    color: theme.$brand-navy-4;
    font-size: 18px;
    font-weight: bold;
  }
  .mastodon-toggle {
    padding-top: 32px;
    label {
      transform: scale(0.8);
    }
    .cds--toggle__text {
      span {
        margin-left: 0;
      }
    }
  }

  .settings-description {
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    margin-left: 3.6rem;
    margin-bottom: 0.5rem;
    color: theme.$ui-text-primary-light;
    font-size: 14px;
  }
}

.sub-setting {
  .settings-label {
    color: theme.$brand-navy-3 !important;
    font-weight: 500;

    &.disabled {
      color: theme.$ui-text-disabled-light !important;
    }
  }
}
