.location-grid-container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 16px;
  margin-bottom: 16px;
}

.card {
  display: flex !important;
  flex: 1;
  margin-right: 0 !important;
  width: 50%;
  font-weight: normal;
}

.selected-card {
  background-color: lightgrey;
}

.selected-location-panel {
  &:hover {
    background-color: #043f93;
  }
}

.cds--radio-button__label-text {
  width: 100%;
}

label[for=SELECT_CLINIC_AFFIRMATIVE], label[for=SELECT_CLINIC_NEGATIVE] {
  align-items: center !important;
}


 .cds--radio-button__appearance {
  margin: 0 !important;
}

label[for=SELECT_CLINIC_AFFIRMATIVE]  .mastodon-choice-card-title, label[for=SELECT_CLINIC_NEGATIVE] .mastodon-choice-card-title {
  padding-bottom: 0px !important;
  font-weight: normal !important;
}
