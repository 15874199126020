@use '@dashboard-experience/mastodon/lib/theme.scss';

.lite {
  .lookback-rules-table-headers {
    .mastodon-grid-col {
      color: theme.$brand-navy-3;
    }
  }
}

.standard {
  .lookback-rules-table-headers {
    .mastodon-grid-col {
      color: theme.$ui-text-primary-light;
    }
  }
}
