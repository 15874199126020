@use '@dashboard-experience/mastodon/lib/theme.scss';

.billing-header {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 24px 32px;
  border-bottom: 1px solid #E1E6EB;
  border-top: 6px solid #023161;
  position: sticky;
  top: 0;
  z-index: 1000;
  opacity: 1;
  background-color: #ffffff;
  padding-bottom: 1.5rem;
}

.billing-header-title {
  font-family: theme.$fonts-national;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.24px;
  color: theme.$ui-grey-900;
  line-height: 30px;
  opacity: 1;
}
