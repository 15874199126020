@use '@dashboard-experience/mastodon/lib/theme.scss';

.candidate-header {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 24px 32px;
  border-top: 6px solid theme.$ui-grey-300;
  transition: padding 0.3s ease, border-top 0.3s ease;

  &.candidate-header-status-eligible,
  &.candidate-header-status-clear {
    border-top: 6px solid theme.$ui-green-400;

    &.candidate-header-shrink {
      border-top: 3px solid theme.$ui-green-400;
    }
  }

  &.candidate-header-status-escalated,
  &.candidate-header-status-consider {
    border-top: 6px solid theme.$ui-pink-500;

    &.candidate-header-shrink {
      border-top: 3px solid theme.$ui-pink-500;
    }
  }

  &.candidate-header-status-pending,
  &.candidate-header-status-processing,
  &.candidate-header-status-invitation-sent,
  &.candidate-header-status-suspended,
  &.candidate-header-status-canceled,
  &.candidate-header-status-queued,
  &.candidate-header-status-warning,
  &.candidate-header-status-dispute,
  &.candidate-header-status-expired {
    border-top: 6px solid theme.$ui-grey-300;

    &.candidate-header-shrink {
      border-top: 3px solid theme.$ui-grey-300;
    }
  }

  &.candidate-header-status-review,
  &.candidate-header-status-filled {
    border-top: 6px solid theme.$ui-yellow-400;

    &.candidate-header-shrink {
      border-top: 3px solid theme.$ui-yellow-400;
    }
  }

  &.candidate-header-status-complete {
    border-top: 6px solid theme.$ui-navy-700;

    &.candidate-header-shrink {
      border-top: 3px solid theme.$ui-navy-700;
    }
  }
}

.candidate-header-shrink {
  padding: 14px 32px;
  border-top: 3px solid theme.$ui-grey-300;
}

.candidate-header-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  transition: transform 0.3s ease;

  flex-shrink: 0;
  flex-grow: 1;
}

.candidate-header-left-shrink {
  flex-direction: row;
  align-items: center;
}

.candidate-header-name {
  font-family: theme.$fonts-national;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.24px;
  color: theme.$ui-grey-900;
  line-height: 30px;
  opacity: 1;
  margin-bottom: 8px;
  transition: font-size 0.3s ease, font-weight 0.3s ease,
    margin-bottom 0.3s ease, opacity 0.3s ease;
}

.candidate-header-name-shrink {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: unset;
  opacity: 0.7;
  margin-bottom: 0;
}

.candidate-header-right {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  flex-shrink: 1;
  flex-grow: 0;

  #mastodon & {
    .mastodon-button.tertiary {
      margin: 0;
    }
  }
}

.candidate-header-right-shrink {
  flex-wrap: nowrap;
}

.all-reports-action-menu {
  z-index: 900;
}
