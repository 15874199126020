@use '@dashboard-experience/mastodon/lib/theme.scss';

.lite {
  .premade {
    .title {
      color: theme.$ui-grey-700;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
