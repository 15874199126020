@use '@dashboard-experience/mastodon/lib/theme.scss';

.lite {
  .premade {
    .mastodon-icon.expandable-chevron {
      transform: rotate(90deg);
      fill: #16161D;
      width: 24px;
      height: 24px;
    }
    &.expanded {
      .mastodon-icon.expandable-chevron {
        transform: rotate(180deg);
      }
    }
    .header {
      color: #1A2026;
      font-size: 18px;
      font-weight: bold;
    }
    li span {
      color: theme.$ui-grey-700;
    }
  }
}
