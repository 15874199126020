@use '@dashboard-experience/mastodon/lib/theme.scss';

.lite {
  .lookback {
    .mastodon-icon.expandable-chevron {
      transform: rotate(90deg);
      fill: #16161D;
      width: 16px;
      height: 16px;
    }
    &.expanded {
      .mastodon-icon.expandable-chevron {
        transform: rotate(180deg);
      }
    }
    .charges-header {
      color: theme.$ui-grey-700;
      font-size: 14px;
      font-weight: bold;
    }
    li span {
      color: theme.$ui-grey-700;
    }
  }
}
